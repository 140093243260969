.role-title {
  margin-top: 6px;
  font-size: 10px;
  background-color: #a6a6a6;
  color: white;
  padding: 2px 10px;
  border-radius: 8px;
}

#deco-visible .Mui-disabled {
  color: rgba(0, 0, 0, 0.9);
}

#deco-visible .Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
}
